/* CSS styling for the scroll-to-top button. See App.js for details */

.scroll-to-top {
	position: fixed;
	bottom: 20px;
	right: 20px;
	background: darkorange;
	color: lightblue;
	cursor: pointer;
	width: 64px;
	height: 64px;
	font-size: 36px;
	border-radius: 50%;
	border: none;
	box-shadow: 0 5px 10px #ccc;	
	transform: rotate(-90deg);
}


.scroll-to-top:hover {
	background: blue;
}

.scroll-to-top:active {
	bottom: 18px;
	right: 18px;
}
