/* html { */
	/* 
		keep our body element centered regardless of vertical scrollbar appearing/disappearing 
		thanks to: https://stackoverflow.com/a/42987298 
	*/
	/* margin-left: calc(100vw - 100%); */
	/* background-color: whitesmoke; */
/* } */

body {
	/* background-color: rgb(159, 86, 17) !important; */
	background-color: bisque !important;
 	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

h1,h2 {
	text-align: center;
	padding: 0;
	margin: 0;
}

.notes-container h3 {
	margin-bottom: -1rem;
}

label {
	margin-right: 1rem;
	margin-left: 1.5rem;
}

a,
a:visited {
    color: blue;
}


a.active {
	color: white;
	border-bottom: 4px solid blue;
	border-radius: 2px;
}

a.inactive {
	border:none;
}
